<template>
  <div>
    <div class="d-flex align-center gap-2 align-center mb-2">
      <v-text-field
        v-model="search"
        label="Buscar Produto"
        outlined
        dense
        hide-details
        clearable
        append-icon="mdi-magnify"
        placeholder="Busque por nome ou categoria"
      />
      <v-btn @click="ProductModal" color="primary">
        <v-icon left>mdi-plus</v-icon> Produto
      </v-btn>
    </div>
    <!-- <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
      class="mt-2"
    />
    <v-alert v-else-if="!loading && error" class="mb-0" type="error">
      {{ error }}
    </v-alert> -->
    <v-alert v-if="products.length === 0" type="info" text class="w-full mt-2">
      <p class="text-18 font-weight-bold lh-1">
        Seu evento ainda não possui produtos criados.
      </p>
      <p class="text-16 font-weight-bold mb-0">Como criar um produto?</p>
      <p>
        Clique no botão "Produto" acima para adicionar um novo produto ao seu
        catalogo. Você pode adicionar novas categorias para facilitar a
        organização.
      </p>
    </v-alert>
    <v-row v-else class="mx-0">
      <v-col cols="12" v-for="product in products" :key="product.id">
        <product-card :product="product" :categories="categories" />
      </v-col>
    </v-row>

    <product-modal :categories="categories" />
    <category-modal />
    <product-stock />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ProductModal from "./modal/ProductModal.vue";
import CategoryModal from "./modal/CategoryModal.vue";
import ProductCard from "./ProductCard.vue";
import ProductStock from "./modal/ProductStock.vue";

export default {
  components: { ProductModal, CategoryModal, ProductCard, ProductStock },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    loading: true,
    error: null,
    search: "",
  }),
  methods: {
    ProductModal() {
      this.$root.$emit("product-modal");
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    ...mapGetters("auth", ["hasPermission"]),
  },
  watch: {},
};
</script>

<style></style>
