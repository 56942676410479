<template>
  <base-card class="pb-2 rounded-lg" :loading="loading">
    <v-tabs v-model="tab" show-arrows class="rounded-t-lg">
      <v-tab
        v-for="tab in !products ? [] : tabsFiltered"
        class="rounded-t-lg"
        :key="tab.component"
      >
        <v-icon left small>{{ tab.icon }}</v-icon>
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in !products ? [] : tabsFiltered"
        :key="item.name"
      >
        <component
          :is="item.component"
          :products="products"
          :categories="categories"
          class="px-4 pb-4 pt-3"
        />
      </v-tab-item>
    </v-tabs-items>
  </base-card>
</template>

<script>
import PRODUCT from "@/services/admin/products";

import Management from "@/components/admin/products/Management.vue";
import PosCashoutReports from "@/components/admin/pos/PosCashoutReports.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  metaInfo() {
    return { title: `Produtos` };
  },
  data: () => ({
    loading: true,
    tab: null,
    products: false,
    categories: [],
    tabs: [
      {
        id: "management",
        name: "Gerenciar",
        component: "Management",
        permission: [16384, 2097152],
        icon: "mdi-printer-pos",
      },
      // {
      //   id: "orders",
      //   name: "Compras",
      //   component: "PosCashoutReports",
      //   permission: 1073741824,
      //   icon: "mdi-cash",
      // },
      // {
      //   id: "pickup",
      //   name: "Retirada",
      //   component: "",
      //   permission: 1048576,
      //   icon: "mdi-package-check",
      // },
    ],
  }),
  methods: {
    ...mapActions("organization", ["updateMemberships"]),
    async getProducts() {
      try {
        this.loading = true;
        const organizationId = this.selectedOrganization.id;
        const data = await PRODUCT.getAll(organizationId);
        this.products = data.products;
        this.categories = data.categories;
        this.selectTab();
      } catch (e) {
        this.error =
          e.message || "Ocorreu um erro ao buscar os membros filiados.";
      } finally {
        this.loading = false;
      }
    },
    selectTab() {
      if (this.$route.hash) {
        let hash = this.$route.hash.replace("#", "");
        let index = this.tabsFiltered.findIndex((tab) => tab.id == hash);
        if (index) this.tab = index;
      }
    },
    vibrate() {
      if (navigator.vibrate) navigator.vibrate(50);
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization"]),
    tabsFiltered() {
      return this.tabs.filter(
        (tab) => !tab.permission || this.hasPermission(tab.permission)
      );
    },
  },
  watch: {
    "selectedOrganization.id"() {
      this.getProducts();
    },
    "$route.hash"() {
      this.selectTab();
    },
    tab: {
      handler(val, oldVal) {
        if (val !== null && oldVal !== null) this.vibrate();
        let tab = this.tabsFiltered[val];
        if (tab) window.location.replace("#" + tab.id);
      },
      immediate: true,
    },
  },

  mounted() {
    this.getProducts();
    this.$root.$on("category-created", (category) => {
      this.categories.push(category);
    });
    this.$root.$on("product-created", (product) => {
      this.getProducts();
    });
    this.$root.$on("product-updated", (product) => {
      this.getProducts();
    });
  },
  components: {
    Management,
    PosCashoutReports,
  },
};
</script>

<style></style>
