<template>
  <v-dialog
    v-model="isOpen"
    width="1100"
    persistent
    scrollable
    content-class="rounded-lg"
  >
    <v-card class="h-full d-flex flex-column">
      <v-card-text class="ma-0 pa-6 pt-0 pb-0">
        <v-row>
          <v-col cols="12" md="6">
            <v-tabs grow>
              <v-tab>Geral</v-tab>
              <v-tab>
                Variantes
                <v-icon right v-if="!checkVariantsIsValid" color="red" small>
                  mdi-alert-circle
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-text-field
                  v-model="product.name"
                  label="Nome do produto"
                  outlined
                  dense
                  class="mt-4"
                  :rules="[
                    (v) => !!v || 'Name é obrigatório',
                    (v) =>
                      (v && v.length <= 30) ||
                      'Name deve ter menos de 30 caracteres',
                  ]"
                />

                <v-textarea
                  v-model="product.description"
                  label="Descrição"
                  outlined
                  dense
                  :rules="[
                    (v) => !!v || 'Descrição é obrigatório',
                    (v) =>
                      (v && v.length <= 100) ||
                      'Descrição deve ter menos de 100 caracteres',
                  ]"
                />
                <div class="d-flex gap-2">
                  <v-autocomplete
                    v-model="product.categoryId"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    outlined
                    label="Categoria"
                    dense
                    :filter="filterCategory"
                    :rules="[(v) => !!v || 'Categoria é obrigatória']"
                    @change="selectCategory"
                    hide-details="auto"
                  >
                    <template v-slot:selection="{ item }">
                      <v-icon left x-small>{{ item.icon }}</v-icon>
                      {{ item.name }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-icon left>{{ item.icon }}</v-icon>
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <template v-slot:append-item>
                      <v-btn class="w-full" text @click="newCategory">
                        Nova Categoria <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                  </v-autocomplete>
                </div>
                <div class="mt-3">
                  <span>Imagem do Produto</span>
                  <file-drop
                    @change="loadFile"
                    :disabled="!!loading"
                    accept=".png,.jpg,.jpeg,.webp,"
                    label="Arraste a imagem do produto ou clique para selecionar"
                  />
                  <p
                    v-if="product.image.url"
                    class="px-2 text-center text-12 text--secondary"
                  >
                    Formato recomendado: proporção 4:3 e mínimo de 800x600px,
                    peso máximo de 1MB.
                  </p>
                </div>
              </v-tab-item>
              <v-tab-item>
                <v-card>
                  <v-card-text
                    ><template>
                      <v-expansion-panels>
                        <variant-form
                          v-for="(item, i) in variants"
                          :key="item.id"
                          v-model="variants[i]"
                          :index="i"
                          :count="variants.length"
                          :product="product"
                        />
                        <v-btn
                          :disabled="!checkVariantsIsValid"
                          class="w-full mt-1"
                          text
                          @click="newVariant"
                        >
                          <v-icon>mdi-plus</v-icon> Adicionar nova Variante
                        </v-btn>
                      </v-expansion-panels>
                    </template>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
          <v-col cols="12" md="6">
            <product-gallery :product="product" :variants="variants" />
          </v-col>
        </v-row>
        <v-alert v-if="error" type="error">{{ error }}</v-alert>
      </v-card-text>

      <v-card-actions class="d-flex align-end gap-1 justify-space-between">
        <v-btn text :disabled="loading" @click="close"> Cancelar </v-btn>
        <v-btn color="success" :loading="loading" @click="saveProduct()">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import FileDrop from "../../../global/FileDrop.vue";
import VariantForm from "./VariantForm.vue";
import ProductGallery from "../ProductGallery.vue";
import PRODUCT from "@/services/admin/products";
import { v4 as uuidv4 } from "uuid";

const defaultVariant = () => ({
  id: uuidv4(),
  name: "",
  description: "",
  price: 0,
  Images: [],
});

export default {
  components: { FileDrop, VariantForm, ProductGallery },
  data: () => ({
    isOpen: false,
    isValid: false,
    loading: false,
    error: null,
    variants: [defaultVariant()],
    product: {
      id: null,
      name: "",
      description: "",
      price: 0,
      categoryId: null,
      image: {
        url: "",
        file: null,
      },
    },
  }),

  methods: {
    newVariant() {
      this.variants.push(defaultVariant());
    },
    open(prod) {
      this.isOpen = true;
      this.loading = false;
      this.error = false;
      if (prod) {
        const { Variants, ...product } = prod;
        this.product = product;
        this.variants = Variants;
      } else {
        this.product = {
          id: null,
          name: "",
          description: "",
          price: 0,
          categoryId: null,
          image: {
            url: "",
            file: null,
          },
        };
      }
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.error = false;
    },
    newCategory() {
      console.log("new category");
      this.$root.$emit("category-modal");
    },
    selectCategory(key) {
      this.product.category = key;
    },
    filterCategory(item, queryText) {
      const name = item.name;
      const text = this.textClean(name);
      const query = this.textClean(queryText);
      return text.toLowerCase().indexOf(query.toLowerCase()) > -1;
    },
    textClean(text) {
      return text
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/([^\w]+|\s+)/g, "-")
        .replace(/\-\-+/g, "-")
        .replace(/(^-+|-+$)/, "");
    },
    async saveProduct() {
      this.loading = true;
      this.error = null;
      const organizationId = this.selectedOrganization.id;
      const product = {
        ...this.product,
        image: undefined,
        variants: this.variants.map(({ image, ...v }) => ({
          ...v,
          name: v.name || this.product.name,
        })),
      };
      var data;
      try {
        if (!this.product.id) {
          console.log(product);
          data = await PRODUCT.create(organizationId, product);
          this.product.id = data.product.id;
        } else {
          console.log(this.product);
          await PRODUCT.updateProduct(
            organizationId,
            this.product.id,
            this.product
          );
          this.$emit("refresh");
        }

        // Upload product image
        if (this.product.image.file)
          await PRODUCT.uploadImage(
            organizationId,
            this.product.id,
            this.product.image.file
          );

        // Upload variant images
        for (const variant of this.variants) {
          for (const image of variant.Images) {
            if (image.file)
              await PRODUCT.uploadVariantImage(
                organizationId,
                this.product.id,
                variant.id,
                image.file
              );
          }
        }

        this.$emit("success");
        this.$root.$emit("product-created", this.product);
        this.close();
      } catch (error) {
        this.error = error.message || "Erro ao salvar produto";
      } finally {
        this.loading = false;
      }
    },

    async uploadImage() {},
    async uploadVariantImage() {},
    loadFile(file) {
      this.product.image = {
        url: URL.createObjectURL(file),
        file,
      };
    },
  },
  filters: {},
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization"]),

    checkVariantsIsValid() {
      return this.variants.every((variant) => variant.valid);
    },
  },
  mounted() {
    this.$root.$on("product-modal", this.open);
    this.$root.$on("category-created", (category) => {
      if (this.isOpen) this.product.categoryId = category.id;
    });
  },
  props: {
    categories: Array,
  },
  created() {},
};
</script>

<style></style>
