<template>
  <v-card outlined :loading="loading" v-intersect="onIntersect">
    <v-card-title class="justify-space-between">
      <h6 class="mb-0 lh-1">Padrão de compra</h6>
    </v-card-title>
    <v-card-text>
      <div class="d-flex flex-wrap justify-space-around gap-y-3">
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">
            {{
              (filteredData.tickets / (filteredData.payments || 1)).toFixed(2)
                | number
            }}
          </h6>
          <span class="text-caption">Ingressos por Compra</span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">
            {{ (filteredData.total / filteredData.payments) | currency }}
          </h6>
          <span class="text-caption">Ticket Médio por Compra</span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">
            {{ filteredData.payments | number }}
          </h6>
          <span class="text-caption">Compras</span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">
            {{ filteredData.tickets | number }}
          </h6>
          <span class="text-caption">Ingressos</span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">
            {{ filteredData.total | currency }}
          </h6>
          <span class="text-caption">Valor Líquido</span>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    viewed: false,
    isIntersecting: false,
  }),
  methods: {
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.isIntersecting = true;
        this.viewed = true;
      } else {
        this.isIntersecting = false;
      }
    },
  },
  computed: {
    filteredData() {
      if (!this.viewed) return { payments: 0, tickets: 0, total: 0 };
      const data = {
        payments: 0,
        tickets: 0,
        total: 0,
      };
      this.data.payments.filter((payment) => {
        if (payment.paymentType === "COURTESY") return;
        if (payment.status !== "SUCCESS") return;
        
        data.payments += 1;
        data.tickets += payment.Ticket.length;
        data.total += payment.Ticket.reduce(
          (acc, ticket) => acc + ticket.amount - ticket.platformFee,
          0
        );
      });
      return data;
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>
