<template>
  <div>
    <div class="d-flex justify-space-between align-center mt-2">
      <div class="d-flex align-center gap-1">
        <h6 class="mb-0">Setor</h6>
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text icon v-bind="attrs" v-on="on">
                <v-icon small>mdi-sort</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in filteredTicketGroups"
                :key="index"
                @click="sortTicketGroup(item.params)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <div v-if="party.active">
        <v-btn
          icon
          :loading="loading"
          @click="getTickets"
          :small="$vuetify.breakpoint.mdAndDown"
          class="mr-2"
          :disabled="disableRefresh"
        >
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
        <v-btn
          :disabled="loading"
          color="primary"
          @click="addGroup"
          :small="$vuetify.breakpoint.mdAndDown"
          v-if="party.active"
        >
          <v-icon left :small="$vuetify.breakpoint.mdAndDown">
            mdi-plus
          </v-icon>
          Setor
        </v-btn>
      </div>
    </div>

    <!-- Loading if is loading -->
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
      class="mt-2"
    />
    <v-alert v-if="error" type="error" class="mt-2">
      {{ error }}
    </v-alert>

    <!-- progress bar with total sold / total tickets -->
    <v-progress-linear
      v-else
      :indeterminate="loading"
      :value="ticketSoldPercentage * 100"
      :color="
        ticketSoldPercentage > 0.9
          ? 'error'
          : ticketSoldPercentage > 0.7
          ? 'warning'
          : 'primary darken-3'
      "
      :height="loading ? null : 20"
      class="mt-2 rounded"
    >
      <b class="white--text">
        {{ totalSold }} de {{ totalTickets }} ({{
          (ticketSoldPercentage * 100).toFixed(0)
        }}%) ingresso{{ totalTickets != 1 ? "s" : "" }} vendido{{
          totalTickets != 1 ? "s" : ""
        }}
      </b>
    </v-progress-linear>

    <ticket-group-management
      v-if="ticketGroups.length > 0"
      :ticketGroups="ticketGroups"
      :tableMap="tableMap"
      :party="party"
      @updateTicketGroupOrder="onEndTicketGroup"
    />

    <!-- Modals -->
    <ticket-group :party="party" :tableMap="tableMap" @success="getTickets" />
    <ticket-block
      :party="party"
      :ticketGroups="ticketGroups"
      @success="getTickets"
      :posSessions="posSessions"
    />
    <kit
      :party="party"
      :ticketGroupId="ticketGroups"
      :posSessions="posSessions"
      @success="getTickets"
    />

    <kit-multiple-create
      :party="party"
      :ticketGroups="ticketGroups"
      @success="getTickets"
    />

    <ticket-manager-op-mode
      v-if="config.opMode"
      :party="party"
      :ticketGroups="ticketGroups"
      @refresh="getTickets"
    />

    <v-alert type="info" class="mt-6 mb-0" dense text>
      <div class="d-flex justify-space-between align-center">
        <div>
          <b>Gerenciar os vendedores</b>
          <p class="mb-0">Perfil no evento e lotes com permissão de venda.</p>
        </div>
        <v-btn v-if="party.active" small :to="{ hash: 'sellers' }" depressed>
          Vendedores
        </v-btn>
      </div>
    </v-alert>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TICKET from "@/services/admin/ticket";
import TABLE from "@/services/admin/party/table";
import POS from "@/services/admin/pos";

import TicketGroup from "@/components/admin/modals/TicketGroup";
import TicketBlock from "@/components/admin/modals/TicketBlock";
import Kit from "@/components/admin/modals/Kit";
import TicketGroupManagement from "./ticketManagement/TicketGroupManagement.vue";
import TicketManagerOpMode from "./TicketManagerOpMode.vue";
import KitMultipleCreate from "../modals/KitMultipleCreate.vue";

export default {
  components: {
    TicketGroup,
    TicketBlock,
    TicketGroupManagement,
    TicketManagerOpMode,
    Kit,
    KitMultipleCreate,
  },
  data: () => ({
    loading: false,
    error: false,
    ticketGroups: [],
    posSessions: [],
    tableMap: null,
    disableRefresh: false,
    filteredTicketGroups: [
      {
        title: "Nomes A-Z",
        value: "name-asc",
        icon: "mdi-sort-alphabetical-ascending",
        params: ["name", -1],
      },
      {
        title: "Nomes Z-A",
        value: "name-desc",
        icon: "mdi-sort-alphabetical-descending",
        params: ["name", 1],
      },
    ],
  }),
  methods: {
    ...mapActions("organization", ["updateMemberships"]),
    addGroup(tGroup) {
      this.$root.$emit("ticket-group-modal", tGroup);
    },
    sortTicketGroup(params) {
      this.ticketGroups.sort((a, b) => {
        if (a[params[0]] < b[params[0]]) return params[1];
        if (a[params[0]] > b[params[0]]) return -params[1];
        return 0;
      });
      this.saveNewTicketGroupOrder();
    },
    async saveNewTicketGroupOrder() {
      try {
        this.loading = true;
        const orgId = this.party.organizationId;
        const partyId = this.party.id;
        const newOrder = this.ticketGroups.map((tGroup) => tGroup.id);

        const data = {
          order: newOrder,
        };

        await TICKET.reorderTicketGroup(orgId, partyId, data);
      } catch (error) {
        console.error("Failed to update order:", error);
      } finally {
        this.loading = false;
      }
    },
    async getTickets(fullLoad = false) {
      this.loading = true;
      try {
        const response = await TICKET.getTickets(
          this.party.organizationId,
          this.party.id
        );
        this.ticketGroups = response.ticketGroups;

        if (fullLoad) await this.fullLoad();

        this.disableRefresh = true;
        setTimeout(() => {
          this.disableRefresh = false;
        }, 5000);
      } catch (error) {
        this.error = error.message || "Erro ao buscar lotes de ingressos.";
      } finally {
        this.loading = false;
      }
    },
    async fullLoad() {
      await this.loadTableMap();
      await this.updateMemberships();
      await this.getPos();
    },
    async getPos() {
      try {
        const orgId = this.party.organizationId;
        const { sessions } = await POS.getAll(orgId);
        this.posSessions = sessions;
      } catch (error) {
        this.error = error.message || "Erro ao buscar os POS";
        console.error(error);
      }
    },
    async loadTableMap() {
      try {
        const { tableMap } = await TABLE.get(
          this.party.organizationId,
          this.party.id
        );
        this.tableMap = tableMap || { backgroundUrl: null, Groups: [] };
      } catch (error) {
        this.error = error.message || "Erro ao carregar avaliações";
      }
    },
    onEndTicketGroup({ oldIndex, newIndex }) {
      if (oldIndex !== newIndex) {
        const item = this.ticketGroups[oldIndex];
        this.ticketGroups.splice(oldIndex, 1);
        this.ticketGroups.splice(newIndex, 0, item);
        this.saveNewTicketGroupOrder();
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["config"]),
    totalSold() {
      return this.ticketGroups.reduce((acc, tGroup) => {
        acc += tGroup.TicketBlock.reduce(
          (abb, tBlock) => abb + tBlock.quantity - tBlock.tickets.remaining,
          0
        );
        return acc;
      }, 0);
    },
    totalTickets() {
      return this.ticketGroups.reduce((acc, tGroup) => {
        acc += tGroup.TicketBlock.reduce(
          (abb, tBlock) => abb + tBlock.quantity,
          0
        );
        return acc;
      }, 0);
    },
    ticketSoldPercentage() {
      if (this.totalTickets == 0) return 0;
      return this.totalSold / this.totalTickets;
    },
  },
  watch: {
    party: {
      handler: function (newVal, oldVal) {
        this.partyBkp = { ...newVal };
      },
      deep: true,
    },
  },
  mounted() {
    this.getTickets(true);
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>
