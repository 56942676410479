<template>
  <div>
    <div class="d-flex justify-space-between align-center mt-2">
      <h6 class="mb-0">Cupons</h6>
      <v-btn
        @click="addCoupon"
        color="primary"
        :small="$vuetify.breakpoint.mdAndDown"
      >
        <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-plus</v-icon>
        <template v-if="$vuetify.breakpoint.smAndUp">Cupom</template>
      </v-btn>
    </div>
    <pre>{{ coupons }}</pre>

    <coupon-modal :party="party" @refresh="getCoupons" />
  </div>
</template>

<script>
import COUPON from "@/services/admin/party/coupon";
import CouponModal from "./coupon/CouponModal.vue";
export default {
  components: { CouponModal },
  data: () => ({
    coupons: [],
  }),
  methods: {
    async getCoupons() {
      try {
        const { coupons } = await COUPON.getAll(
          this.party.organizationId,
          this.party.id
        );
        this.coupons = coupons;
      } catch (error) {
        console.error(error);
      }
    },

    addCoupon() {
      this.$root.$emit("coupon-modal");
    },
  },
  mounted() {
    this.getCoupons();
  },
  props: {
    party: Object,
  },
};
</script>

<style>
</style>