<template>
  <div>
    <div class="d-flex justify-space-between mb-3">
      <h6 class="mb-0">
        {{ displayType == "payment" ? "Pagamentos" : "Ingressos" }}
      </h6>
      <v-btn-toggle class="px-2" mandatory v-model="displayType">
        <v-btn x-small value="payment">
          <v-icon small small>mdi-cash-multiple</v-icon>
          <span class="hidden-sm-and-down ml-2">Pagamentos</span>
        </v-btn>
        <v-btn x-small value="ticket">
          <v-icon small small>mdi-ticket</v-icon>
          <span class="hidden-sm-and-down ml-2">Ingressos</span>
        </v-btn>
      </v-btn-toggle>
    </div>

    <v-data-table
      dense
      :items="items"
      :headers="computedHeaders"
      sort-by="createdAt"
      sort-desc
    >
      <!-- :items-per-page="payments" -->
      <template #item.id="{ item }"> #{{ item.id | shortId }} </template>
      <template #item.amount="{ item }">
        <div
          v-if="displayType != 'ticket'"
          class="d-flex flex-column align-center"
        >
          <span class="font-weight-medium lh-2">
            {{ item.amount | currency }}
          </span>
          <small class="">
            {{ item.tickets.length }} ingresso{{
              item.tickets.length > 1 ? "s" : ""
            }}
          </small>
        </div>
        <div v-else class="d-flex flex-column align-center">
          <span class="font-weight-medium lh-2">
            {{ (item.amount - (item.clientFee || 0)) | currency }}
          </span>
          <small class=""> +{{ item.clientFee | currency(true) }} taxa </small>
        </div>
      </template>
      <template #item.createdAt="{ item }">
        {{ item.createdAt | date("DD/MM/YY HH:mm") }}
      </template>
      <template #item.TicketBlock="{ item }">
        <span class="">{{ item.TicketBlock.TicketGroup.name }}</span>
        <small class="d-block">{{ item.TicketBlock.name }}</small>
      </template>
      <template #item.status="{ item }">
        <v-chip label small :color="item.status.color">
          {{ item.status.text }}
        </v-chip>
      </template>
      <template #item.action="{ item }">
        <v-btn
          icon
          @click="
            displayType === 'payment'
              ? paymentDetails(item)
              : ticketDetails(item)
          "
        >
          <v-icon>mdi-information</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import PAYMENT from "@/utils/payment";
import ticket from "../../../../services/admin/ticket";

export default {
  components: {},
  props: {
    tickets: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    ticketStatus: PAYMENT.ticketStatus,
    displayType: "payment",
    headers: [
      {
        text: "ID",
        value: "id",
        sortable: false,
        width: 10,
        cellClass: "text-overline lh-1",
        for: ["payment", "ticket"],
      },
      { text: "Lote", value: "TicketBlock", align: "center", for: ["ticket"] },
      {
        text: "Valor",
        value: "amount",
        align: "center",
        for: ["payment", "ticket"],
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
        align: "center",
        for: ["payment", "ticket"],
      },
      {
        text: "Data",
        value: "createdAt",
        align: "end",
        for: ["payment", "ticket"],
      },
      {
        text: "",
        value: "action",
        align: "center",
        for: ["payment", "ticket"],
        cellClass: "px-1",
      },
    ],
  }),
  computed: {
    payments() {
      return Object.values(
        this.tickets.reduce((acc, { Payment, ...ticket }) => {
          if (!acc[Payment.id]) {
            acc[Payment.id] = {
              ...Payment,
              status: this.calculateStatus(Payment),
              tickets: [],
            };
          }
          acc[Payment.id].tickets.push({
            ...ticket,
          });
          return acc;
        }, {})
      );
    },
    computedTickets() {
      return this.tickets.map((ticket) => ({
        ...ticket,
        status: this.calculateStatus(ticket.Payment),
      }));
    },
    items() {
      return this.displayType === "payment"
        ? this.payments
        : this.computedTickets;
    },
    computedHeaders() {
      return this.headers.filter((h) => h.for.includes(this.displayType));
    },
  },
  methods: {
    refresh(close = false) {
      this.$emit("refresh", close);
    },
    paymentDetails(payment) {
      this.$root.$emit("payment-details", payment);
    },
    ticketDetails(ticket) {
      this.$root.$emit("ticket-details", ticket);
    },

    calculateStatus(payment) {
      return {
        value: payment.status,
        ...(this.ticketStatus[payment.status] || {
          color: "secondary",
          text: "Não pago",
        }),
      };
    },
  },
};
</script>

<style></style>
