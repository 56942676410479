<template>
  <v-dialog v-model="dialog" :max-width="step === 3 ? 500 : 800">
    <v-card>
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            Relatório
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="step > 2" step="2">
            Filtros
          </v-stepper-step>
          <v-divider />
          <v-stepper-step step="3"> Exportar </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card-text>
              <v-row dense align="stretch">
                <v-col
                  v-for="report in mapppedReports"
                  :key="report.id"
                  cols="6"
                  sm="4"
                  md="3"
                >
                  <v-card
                    class="text-center pa-3 h-full d-flex flex-column justify-center"
                    rounded="lg"
                    outlined
                    :class="{
                      ' v-alert--text primary--text':
                      selectedReportId === report.id,
                    }"
                    @click="selectedReportId = report.id"
                    >
                    :disabled="report.valid !== true"
                    <v-icon
                      :color="selectedReportId === report.id ? 'primary' : ''"
                      large
                    >
                      {{ report.icon }}
                    </v-icon>
                    <h6 class="mb-0">{{ report.title }}</h6>
                    <p class="mb-0">{{ report.description }}</p>
                    <small
                      v-if="report.valid !== true"
                      class="error--text lh-1"
                    >
                      {{ report.valid }}
                    </small>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <div class="d-flex justify-space-between">
              <v-btn text @click="close()">Cancelar</v-btn>
              <v-btn
                color="primary"
                @click="step = 2"
                :disabled="!selectedReportId"
              >
                Continuar
              </v-btn>
            </div>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div v-if="loading" class="d-flex justify-center">
              <v-progress-circular indeterminate></v-progress-circular>
            </div>
            <v-alert v-else-if="error" type="error" text>
              {{ error }}
            </v-alert>
            <v-card-text v-else>
              <component
                :is="components.filter"
                v-model="filterOption"
                @isValid="validFilter = $event"
                :ticketGroups="ticketGroups"
                :posSellers="posSellers"
              />
            </v-card-text>
            <v-card-actions
              v-if="!loading"
              class="d-flex justify-space-between"
            >
              <v-btn text @click="step = 1">Voltar</v-btn>
              <v-btn
                v-if="!error"
                color="primary"
                @click="step = 3"
                :disabled="!validFilter"
              >
                Continuar
              </v-btn>
            </v-card-actions>
          </v-stepper-content>
          <v-stepper-content step="3">
            <!-- <vue-html2pdf
              :show-layout="!true"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="false"
              :paginate-elements-by-height="1400"
              :filename="`${party.name} - ${selectedReport?.title}`"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="a4"
              pdf-orientation="portrait"
              pdf-content-width=""
              @progress="onProgress($event)"
              @hasDownloaded="pdfLoading = false"
              ref="html2Pdf"
            >
              <section class="v-application" slot="pdf-content">
                <v-card class="pa-6" style="min-width: 800px">
                  <report-header
                    :party="party"
                    :selectedReport="selectedReport"
                  />
                  <applied-filters
                    v-if="!selectedReport?.hideFilters"
                    :filterOption="filterOption"
                  />
                </v-card>
                <component
                  :is="components.view"
                  :filter="filterOption"
                  :reportData="filteredData"
                  :ticketGroups="ticketGroups"
                  :posSellers="posSellers"
                />
              </section>
            </vue-html2pdf> -->

            <div class="d-flex flex-column align-center justify-center pa-6">
              <template v-if="error">
                <v-icon x-large color="error" class="mb-2">
                  mdi-alert-circle
                </v-icon>
                <h6 class="error--text">{{ error }}</h6>

                <v-btn small color="primary" depressed @click="step = 2">
                  Tentar Novamente
                </v-btn>
              </template>
              <template v-else-if="this.pdfLoading">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="mb-3"
                ></v-progress-circular>
                <h6>Gerando Relatório</h6>
              </template>
              <template v-else>
                <v-icon x-large color="success" class="mb-2">
                  mdi-file-pdf-box
                </v-icon>
                <h6>Relatório Gerado</h6>
                <v-btn small color="primary" depressed @click="generatePdf">
                  Baixar Novamente
                </v-btn>
              </template>
            </div>

            <v-card-actions
              v-if="!loading"
              class="d-flex justify-space-between"
            >
              <v-btn text disabled>Voltar</v-btn>
              <v-btn text @click="close()">Fechar</v-btn>
            </v-card-actions>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import AppliedFilters from "../export/AppliedFilters.vue";
import ReportHeader from "../export/ReportHeader.vue";

const reportsComponents = {};
const context = require.context("./reports", true, /\.(vue|js)$/);

context.keys().forEach((key) => {
  const componentName = key.replace(/^\.\/(.*)\.\w+$/, "$1");
  reportsComponents[componentName] = () => context(key);
});

export default {
  props: {
    ticketGroups: {
      type: Array,
      default: () => [],
    },
    posSellers: {
      type: Array,
      default: () => [],
    },
    party: {
      type: Object,
      default: () => ({}),
    },
    reportData: {
      type: Object,
      default: () => ({}),
    },
    sellers: {
      type: Array,
      default: () => [],
    },
    saleLinks: {
      type: Array,
      default: () => [],
    },
  },
  components: { VueHtml2pdf, ReportHeader, AppliedFilters },
  data: () => ({
    dialog: false,
    step: 1,
    selectedReportId: null,
    loading: false,
    pdfLoading: false,
    error: null,
    components: {},
    filterOption: {},
    validFilter: true,
    filteredData: [],
    reportTypes: [
      {
        id: "sales_by_pdv",
        title: "Vendas por PDV",
        description: "Relatório de vendas por PDV",
        icon: "mdi-cash-register",
        components: "salesByPdv",
        prerequirements: ({ posSellers }) =>
          !!posSellers.length || "Nenhum PDV",
      },
      // {
      //   id: "sales_by_sale_link",
      //   title: "Vendas por Link",
      //   description: "Relatório de vendas por Link de Venda",
      //   icon: "mdi-link-box",
      //   components: "salesByProduct",
      //   prerequirements: ({ saleLinks }) =>
      //     !!saleLinks.length || "Nenhum link de venda",
      // },
    ],
  }),
  methods: {
    open() {
      this.dialog = true;
      this.step = 1;
    },
    close() {
      this.dialog = false;
    },
    async loadReport() {
      try {
        this.loading = true;
        this.error = null;
        this.components = {};
        const filterPath = `${this.selectedReport.components}/Filters`;

        if (reportsComponents[filterPath]) {
          const filterComponent = await reportsComponents[filterPath]();
          this.components.filter = filterComponent.default;
        } else throw { code: 404, message: "Relatório não encontrado" };

        const generatorPath = `${this.selectedReport.components}/generator`;
        if (reportsComponents[generatorPath]) {
          const generatorComponent = await reportsComponents[generatorPath]();
          this.components.generator = generatorComponent.default;
        } else throw { code: 404, message: "Gerador não encontrado" };

        // const viewPath = `${this.selectedReport.components}/ReportView`;
        // if (reportsComponents[viewPath]) {
        //   const viewComponent = await reportsComponents[viewPath]();
        //   this.components.view = viewComponent.default;
        // } else
        //   throw new Error({ code: 404, message: "Relatório não encontrado" });
      } catch (e) {
        this.error = e.message || "Erro ao carregar relatório";
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    filteredReports() {
      return this.reportData.payments.filter((payment) => {
        if (
          this.filterOption.status &&
          !this.filterOption.status.includes(payment.status)
        )
          return false;
        if (this.filterOption.dates && !this.filterDates(payment)) return false;
        if (this.filterOption.ticketBlocks && !this.filterTicketBlocks(payment))
          return false;
        if (this.filterOption.sellers && !this.filterSellers(payment))
          return false;
        if (!this.filterOption.courtesy && !this.filterCourtesy(payment))
          return false;
        return true;
      });
    },
    filterDates(payment) {
      const [startDate, endDate] = this.filterOption.dates;
      const date = new Date(payment.createdAt);
      return date >= startDate && date <= endDate;
    },
    filterTicketBlocks(payment) {
      return !payment.Ticket.some((ticket) =>
        this.filterOption.ticketBlocks.includes(ticket.TicketBlock.id)
      );
    },
    filterSellers(payment) {
      const sellerId = payment.PosSession?.id || payment.Ticket[0]?.Seller?.id;
      if (!sellerId) return true;
      return !this.filterOption.sellers.some(({ id }) => id === sellerId);
    },
    filterCourtesy(payment) {
      return payment.paymentMethod !== "COURTESY";
    },
    onProgress(progress) {
      console.log(progress);
    },
    async generatePdf() {
      this.pdfLoading = true;
      this.error = null;
      try {
        const document = await this.components.generator({
          party: this.party,
          filter: this.filterOption,
          reportData: this.filteredData,
          ticketGroups: this.ticketGroups,
          posSellers: this.posSellers,
        });

        const name = `${this.selectedReport?.title} - ${this.party.name}`;
        const generatedAt = new Date().toLocaleString("pt-BR");

        document.addFooter((doc, { currentPage, totalPages }) => {
          const pageWidth = doc.internal.pageSize.width;
          const pageHeight = doc.internal.pageSize.height;
          doc.setFontSize(8);
          doc.text(`${name} • Gerado em: ${generatedAt}`, 10, pageHeight - 5);

          // Adiciona o texto à direita, alinhado ao lado direito da página
          const currentPageText = `Página ${currentPage} de ${totalPages}`;
          doc.text(
            currentPageText,
            pageWidth - doc.getTextWidth(currentPageText) - 10,
            pageHeight - 5
          );
        });

        document.save(name);

        this.pdfLoading = false;
      } catch (e) {
        console.error(e);
        this.error = "Erro ao gerar relatório";
      }

      // this.$nextTick(() => {
      //   this.$refs.html2Pdf.generatePdf();
      // });
    },
  },

  computed: {
    mapppedReports() {
      return this.reportTypes.map((report) => ({
        ...report,
        valid: !report.prerequirements
          ? true
          : report.prerequirements(this) || false,
      }));
    },
    selectedReport() {
      return this.reportTypes.find(
        (report) => report.id === this.selectedReportId
      );
    },
  },
  mounted() {
    this.$root.$on("exportReport", this.open);
  },
  watch: {
    step(val) {
      if (val === 2) {
        this.loadReport();
        this.filterOption = {};
        this.validFilter = true;
      } else if (val === 3) {
        this.filteredData = this.filteredReports();
        this.$nextTick(() => {
          this.generatePdf();
        });
      }
    },
  },
};
</script>

<style></style>
