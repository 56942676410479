var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',{staticClass:"flex-grow-1"},[(_vm.index === 0 && !_vm.variant.name && _vm.count === 1)?_c('i',[_vm._v(" Variação Padrão ")]):(!_vm.variant.name)?_c('i',[_vm._v(" Variação "+_vm._s(_vm.index + 1)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.variant.name)+" ")])]),_c('div',{staticClass:"flex-grow-0 flex-shrink-1"},[(!_vm.variant.valid)?_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-alert-circle")]):_vm._e()],1)]),_c('v-expansion-panel-content',[_c('v-form',{on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.variant.valid),callback:function ($$v) {_vm.$set(_vm.variant, "valid", $$v)},expression:"variant.valid"}},[_c('div',{staticClass:"h-full d-flex flex-column"},[_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"Nome da variante","outlined":"","dense":"","rules":[
            (v) => !!v || 'Name é obrigatório',
            (v) =>
              (v && v.length >= 3) || 'Name deve ter mais de 3 caracteres',
            (v) =>
              (v && v.length <= 30) || 'Name deve ter menos de 30 caracteres',
          ]},model:{value:(_vm.variant.name),callback:function ($$v) {_vm.$set(_vm.variant, "name", $$v)},expression:"variant.name"}}),_c('v-textarea',{attrs:{"label":"Descrição","outlined":"","counter":100,"rows":2,"dense":"","rules":[
            (v) =>
              !v ||
              v.length <= 100 ||
              'Descrição deve ter menos de 100 caracteres',
          ]},model:{value:(_vm.variant.description),callback:function ($$v) {_vm.$set(_vm.variant, "description", $$v)},expression:"variant.description"}}),_c('v-text-field',{attrs:{"rules":[
            (v) => !!v || 'Preço é obrigatório',
            (v) => v > 0 || 'Preço deve ser maior que 0',
          ],"dense":"","prefix":"R$","label":"Preço","type":"number","step":"0.01","outlined":""},model:{value:(_vm.variant.price),callback:function ($$v) {_vm.$set(_vm.variant, "price", $$v)},expression:"variant.price"}}),_c('div',{staticClass:"mb-6"},[_c('span',[_vm._v("Imagem do Produto")]),_c('file-drop',{attrs:{"disabled":!!_vm.loading,"multiple":true,"accept":".png,.jpg,.jpeg,.webp,","label":"Arraste a imagem do produto ou clique para selecionar"},on:{"change":_vm.addVariants}}),(_vm.variant.Images.length)?_c('v-card',{staticClass:"pa-2 rounded-lg mt-2",attrs:{"outlined":""}},[_c('vue-perfect-scrollbar',{staticClass:"w-full",attrs:{"settings":{
                suppressScrollY: false,
                wheelPropagation: false,
              }}},[_c('div',[_c('div',{staticClass:"d-flex gap-2"},_vm._l((_vm.variant.Images),function(img,i){return _c('div',{key:i},[_c('v-img',{staticClass:"rounded",attrs:{"height":"60","width":"60","aspect-ratio":1,"src":img.url},on:{"click":function($event){return _vm.deleteImage(i)}}},[_c('v-overlay',{attrs:{"color":"error","opacity":"0.7","absolute":"","value":_vm.confirmRemove === i}},[_c('div',[_c('v-icon',[_vm._v("mdi-delete-empty")])],1)])],1)],1)}),0)])])],1):_vm._e()],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }