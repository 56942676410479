<template>
  <v-card class="rounded-lg" :loading="loading">
    <v-row>
      <v-col cols="12" sm="4" :style="bgStyle" class="rounded-lg">
        <div style="position: relative">
          <v-img
            :src="party.cover"
            class="rounded-lg"
            :aspect-ratio="16 / 9"
            style="cursor: pointer"
            :style="{
              filter: !item.isActive ? `grayscale(80%)` : undefined,
            }"
            @click="imgClick(item)"
          >
          </v-img>

          <div
            class="d-flex flex-column align-start gap-1"
            style="position: absolute; top: 8px; left: 8px"
          >
            <v-chip label x-small :color="item.private ? 'info' : 'success'">
              <v-icon x-small left>
                {{ item.private ? "mdi-account-lock" : "mdi-account-group" }}
              </v-icon>
              <span>
                {{ item.private ? "Privado" : "Público" }}
              </span>
            </v-chip>
          </div>
          <div
            v-if="config.opMode"
            class="d-flex flex-column align-start gap-1"
            style="position: absolute; top: 8px; right: 8px"
          >
            <base-copier type="chip" :value="item.id || ''" hide-icon>
              <template v-slot:default="{ copy, icon }">
                <v-chip
                  @click="copy"
                  small
                  label
                  class="text-overline font-weight-medium"
                >
                  #{{ item.id | shortId }}
                  <v-icon right x-small>{{ icon }}</v-icon>
                </v-chip>
              </template>
            </base-copier>
          </div>
        </div>

        <!-- ParentParty -->
        <v-card
          v-if="party.ParentParty"
          class="d-flex align-center mt-2 gap-2 rounded-lg"
          elevation="0"
          @click="scrollToEvent(party.ParentParty.id)"
          outlined
        >
          <div>
            <v-img
              height="40"
              :width="40 * (16 / 9)"
              :src="party.ParentParty.cover"
              class="rounded-l-lg"
              :aspect-ratio="16 / 9"
            />
          </div>
          <div class="d-flex flex-column">
            <span class="text-overline lh-1" style="font-size: 8px !important">
              Evento principal
            </span>
            <h6 class="mb-0 text-12">{{ party.ParentParty.name }}</h6>
          </div>
        </v-card>

        <!-- CoOrganizer -->
        <v-card
          v-if="party.Organization"
          class="d-flex align-center mt-2 gap-2 pa-1 rounded-lg"
          elevation="0"
          outlined
        >
          <div>
            <base-avatar
              :src="party.Organization.photo"
              :seed="party.Organization.id"
              :size="30"
              avatarStyle="shapes"
              color="secondary lighten-2"
            />
          </div>
          <div style="display: grid">
            <span class="text-overline lh-1" style="font-size: 8px !important">
              Organização principal
            </span>
            <h6 class="mb-0 text-12 text-truncate">
              {{ party.Organization.name }}
            </h6>
          </div>
        </v-card>
        
      </v-col>
      <v-col cols="12" sm="">
        <div class="d-flex justify-space-between align-center gap-2">
          <span class="ma-0 text-overline" style="line-height: 1em">
            {{ item.date | date("DD [de] MMM [de] YYYY") }}
          </span>
          
          <v-chip v-if="!item.isActive" label x-small>
            <span v-if="item.status === 'finished'">Encerrado</span>
            <span v-else>Inativo</span>
          </v-chip>
          <v-chip v-else :color="dataDiference.color" label x-small>
            {{
              dataDiference.text.charAt(0).toUpperCase() +
              dataDiference.text.slice(1)
            }}
          </v-chip>
        </div>
        <h5 class="ma-0">
          {{ item.name }}
        </h5>
        <v-divider class="my-2" />
        <v-row dense class="mx-0">
          <template v-for="btn in buttons">
            <v-col :key="btn.text" cols="12" sm="6" md="4">
              <v-card
                v-if="
                  btn.permissions === true ||
                  hasPermission(btn.permissions, item.id)
                "
                @click="btn.offline || btn.action(item)"
                :outlined="!btn.offline"
                height="100%"
                elevation="0"
              >
                <div
                  class="d-flex align-center justify-center flex-column py-2 h-full text-center"
                >
                  <v-icon>{{ btn.icon }}</v-icon>
                  {{ btn.text }}
                  <v-chip v-if="btn.offline" color="secondary" label x-small>
                    <v-icon x-small left>mdi-web-off</v-icon> Indisponível
                  </v-chip>
                </div>
              </v-card>
            </v-col>
          </template>
        </v-row>
        <party-quick-report
          v-if="hasPermission(512, item.id)"
          :party="item"
          class="mx-1"
        />
      </v-col>
    </v-row>
    <party-qr-code ref="qrCode" />
  </v-card>
</template>

<script>
import moment from "moment";
import PartyStatus from "@/utils/partyStatus";
import { mapGetters } from "vuex";
import PartyQrCode from "./PartyQrCode.vue";
import PartyQuickReport from "./PartyQuickReport.vue";

export default {
  components: { PartyQrCode, PartyQuickReport },
  methods: {
    imgClick(id) {
      if (this.hasPermission([16, 64, 512], this.item.id)) this.management(id);
      else this.details(id);
    },
    management() {
      this.$router.push({
        name: "admin.party.management",
        params: { partyId: this.party.id },
      });
    },
    details() {
      this.$router.push({
        name: "admin.party.details",
        params: { partyId: this.party.id },
      });
    },
    feed() {
      this.$router.push({
        name: "admin.party.feed",
        params: { partyId: this.party.id },
      });
    },
    financial() {
      this.$router.push({
        name: "admin.transactions.party",
        params: { partyId: this.party.id },
      });
    },
    ticketValidity() {
      this.$router.push({
        name: "admin.entrance.management",
        params: { partyId: this.party.id },
      });
    },
    share() {
      this.$refs.qrCode.open({
        party: this.party,
        organization: this.selectedOrganization,
      });
    },
    spotlight() {
      this.$root.$emit("spotlight:open", { search: `${this.party.name} ` });
    },
    scrollToEvent(id) {
      this.$vuetify.goTo(`#party-${id}`, {
        duration: 500,
        offset: 0,
      });
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission", "config"]),
    ...mapGetters("organization", ["selectedOrganization"]),
    dataDiference() {
      if (!this.item?.endDate) return { text: "Sem data", color: "error" };
      if (moment().isBefore(this.item.date)) {
        // dias para o evento
        // const days = moment(this.item.date).diff(moment(), "days");
        // if (days == 0)
        //   return {
        //     text: "Hoje",
        //     color: "success",
        //   };

        const duration = moment.duration(moment(this.item.date).diff(moment()));
        return {
          text: duration.humanize(true),
          color: "success",
        };
      } else if (moment().isBefore(this.item.endDate)) {
        return {
          text: "Em andamento",
          color: "warning",
        };
      } else {
        return {
          text: "Encerrado",
          color: "error",
        };
      }
    },
    bgStyle() {
      if (!this.party.cover) return null;

      const color = this.$vuetify.theme.dark ? "#1e1e1e" : "#fff";
      const isXs = this.$vuetify.breakpoint.xsOnly;
      return {
        "background-size": "cover",
        backgroundImage: `linear-gradient(to ${
          isXs ? "bottom" : "right"
        },transparent 0%, ${color} 100%),
          url("${this.party.cover}")`,
        "background-repeat": "no-repeat",
      };
    },
    item() {
      const status = new PartyStatus(this.party);
      return {
        ...this.party,
        status: status.status,
        isActive: status.isActive,
      };
    },
    buttons() {
      var btn = [
        {
          text: "Detalhes",
          color: "success",
          icon: "mdi-eye",
          offlineIcon: "mdi-eye-off",
          permissions: [2],
          action: this.details,
        },
        {
          text: "Operacional",
          color: "primary",
          icon: "mdi-cog",
          offlineIcon: "mdi-cog-off",
          permissions: [16, 64, 512],
          action: this.management,
        },
        {
          text: "Financeiro",
          color: "primary",
          icon: "mdi-cash-multiple",
          offlineIcon: "mdi-cash-off",
          permissions: [2048, 4096],
          action: this.financial,
        },
        // {
        //   text: "Feed",
        //   color: "info",
        //   icon: "mdi-rss",
        //   permissions: [4194304],
        //   action: this.feed,
        // },
        {
          text: "Entrada",
          color: "info",
          icon: "mdi-qrcode-scan",
          permissions: [131072],
          action: this.ticketValidity,
        },
        {
          text: "Compartilhar",
          color: "info",
          icon: "mdi-share",
          permissions: true,
          action: this.share,
          allowOffline: true,
        },

        {
          text: "Todas as Ferramentas",
          color: "info",
          icon: "mdi-magnify",
          permissions: [2],
          action: this.spotlight,
          allowOffline: true,
          filter: (party) => party.isActive,
        },
      ].map((btn) => {
        const offline = this.isOffline && !btn.allowOffline;
        return {
          ...btn,
          offline,
          icon: offline ? btn.offlineIcon || btn.icon : btn.icon,
        };
      });

      return btn.filter(
        (btn) =>
          btn.permissions === true ||
          (this.hasPermission(btn.permissions, this.item.id) &&
            (!btn.filter || btn.filter(this.item)))
      );
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss"></style>
