<template>
  <v-card rounded="xl">
    <v-row class="mx-0">
      <v-col cols="12" md="3">
        <div>
          <product-gallery
            :product="product"
            :variants="product.Variants"
            minSize="50px"
            maxSize="400px"
            miniaturesSize="50px"
          />
        </div>
      </v-col>
      <v-col cols="12" md="9">
        <div class="d-flex flex-column gap-2 h-full py-4">
          <div class="d-flex justify-space-between align-center">
            <div>
              <span class="text-overline lh-1">
                <v-icon
                  v-if="categoriesMap[product.categoryId].icon"
                  small
                  x-small
                >
                  {{ categoriesMap[product.categoryId].icon }}
                </v-icon>
                {{ categoriesMap[product.categoryId].name }}
              </span>
              <h4 class="mb-0 lh-5">{{ product.name }}</h4>
            </div>
            <div class="d-flex gap-2">
              <v-btn @click="openProductStockModal(product)" text>
                <v-icon left>mdi-archive-sync</v-icon> Estoque
              </v-btn>
              <v-btn @click="openEditProductModal(product)" color="primary">
                <v-icon left>mdi-pencil</v-icon> Editar
              </v-btn>
            </div>
          </div>
          <div class="flex-grow-1"></div>
          <div class="d-flex justify-space-between align-end px-1">
            <div>
              <span v-if="price.startsAt">A partir de</span>
              <span v-else>Por</span>
              <h4 class="mb-0">{{ price.amount | currency(true) }}</h4>
            </div>
            <div :class="stock < 1 ? 'error--text' : null">
              <span>Estoque</span>
              <h5
                class="mb-0 text-end"
                :class="stock < 1 ? 'error--text' : null"
              >
                {{ stock | number }}
              </h5>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { icon } from "leaflet";
import ProductGallery from "./ProductGallery.vue";

export default {
  components: { ProductGallery },
  props: {
    product: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  computed: {
    price() {
      const priceSet = new Set(
        this.product.Variants.map((variant) => variant.price)
      );
      const startsAt = priceSet.size > 1;
      const amount = Math.min(...priceSet);

      return { startsAt, amount };
    },
    stock() {
      return this.product.Variants.reduce((acc, variant) => {
        acc += variant.quantity - variant.sold;
        return acc;
      }, 0);
    },
    categoriesMap() {
      return this.categories.reduce((acc, category) => {
        acc[category.id] = category;
        return acc;
      }, {});
    },
  },
  methods: {
    openProductStockModal(product) {
      this.$root.$emit("open-product-stock-modal", product);
    },
    openEditProductModal(product) {
      this.$root.$emit("product-modal", product);
    },
  },
};
</script>

<style></style>
