<template>
  <v-dialog v-model="dialog" max-width="600" @click:outside="close" scrollable>
    <v-card :loading="loading" rounded="lg">
      <v-card-title class="text-16 w-full pb-1 justify-space-between">
        <span class="d-flex w-full align-center gap-1 lh-1">
          <div class="w-full">
            <div class="d-flex justify-space-between w-full gap-2" style="flex-wrap: wrap !important;">
              <div class="d-flex gap-2">
                <span class="mb-0 lh-5 text-overline">Ingresso</span>
                <base-copier
                  v-if="ticket"
                  type="chip"
                  :value="ticketId || ''"
                  hide-icon
                >
                  <template v-slot:default="{ copy, icon }">
                    <v-chip
                      @click="copy"
                      x-small
                      label
                      class="text-overline font-weight-medium"
                    >
                      #{{ ticket?.id | shortId }}
                      <v-icon right x-small>{{ icon }}</v-icon>
                    </v-chip>
                  </template>
                </base-copier>
              </div>
              <v-btn
                v-if="ticket"
                x-small
                depressed
                rounded
                :loading="loading"
                :color="PAYMENT.ticketStatus[ticket?.Payment?.status]?.color"
              >
                <v-icon left small>
                  {{ PAYMENT.ticketStatus[ticket?.Payment?.status]?.icon }}
                </v-icon>
                {{ PAYMENT.ticketStatus[ticket?.Payment?.status]?.text }}
              </v-btn>
              <v-skeleton-loader
                v-else
                x-small
                width="100"
                height="22"
                type="card"
                class="rounded-xl"
              />
            </div>
            <div v-if="ticket">
              {{ ticket?.TicketBlock.TicketGroup.name }} •
              {{ ticket?.TicketBlock.name }}
            </div>
            <v-skeleton-loader
              v-else
              v-bind="attrs"
              style="max-width: 60%; margin-top: 2px; margin-bottom: -4px"
              type="text"
            ></v-skeleton-loader>
          </div>
        </span>
      </v-card-title>
      <v-card-text v-if="error">
        <v-alert type="error" class="mb-0 mt-2">
          {{ error }}
        </v-alert>
      </v-card-text>
      <!-- <v-card-text class="pb-0 pt-3">
        <span class="mb-0 lh-5 text-overline"> Dados da compra </span>
      </v-card-text> -->
      <v-card-text class="px-0">
        <div v-if="ticket" class="d-flex flex-column gap-3 mt-3 mx-5">
          <v-alert
            v-if="ticket && ticket.Owner === null"
            class="mx-5 mt-2"
            type="warning"
            text
          >
            Este ingresso não possui uma conta associada
          </v-alert>
          <v-card
            v-else
            outlined
            class="d-flex mt-2 flex-wrap gap-x-4 gap-y-3 pa-3"
          >
            <template v-if="ticket">
              <v-avatar v-if="!ticket?.Owner?.name" :size="44">
                <v-icon large>mdi-account-question</v-icon>
              </v-avatar>
              <base-avatar
                v-else
                :src="ticket?.Owner?.photo"
                :seed="ticket?.Owner?.id"
                :size="44"
              />
              <div class="d-flex flex-column">
                <p class="mb-0 font-weight-bold">
                  {{ ticket?.Owner?.name || "Conta não criada" }}
                </p>
                <p class="mb-0">
                  {{ ticket?.Owner?.phone || "Telefone não cadastrado" }}
                </p>
                {{ ticket?.Owner?.email || "Email não cadastrado" }}
              </div>
            </template>
            <v-skeleton-loader
              v-else
              v-bind="attrs"
              class="flex-grow-1"
              style="margin: -12px"
              type="list-item-avatar-three-line"
            ></v-skeleton-loader>
          </v-card>
          <v-card outlined class="pa-3">
            <h6>Dados da compra</h6>
            <div class="d-flex justify-space-around flex-wrap gap-x-6 gap-y-3">
              <div class="text-center">
                <p class="mb-0 lh-1">Ingressos no carrinho</p>
                <p class="mb-0 font-weight-bold">
                  {{ ticket?.Payment?._count?.Ticket }}
                </p>
              </div>
              <div class="text-center">
                <p class="mb-0 lh-1">Data da compra</p>
                <p class="mb-0 font-weight-bold">
                  {{ ticket?.Payment?.payedAt | date("DD/MM/YYYY HH:mm") }}
                </p>
              </div>
              <div class="text-center">
                <p class="mb-0 lh-1">Método de pagamento</p>
                <p class="mb-0 font-weight-bold">
                  {{
                    PAYMENT.paymentMethod[ticket?.Payment?.paymentMethod].text
                  }}
                </p>
              </div>
              <div class="text-center">
                <p class="mb-0 lh-1">Tipo de pagamento</p>
                <p class="mb-0 font-weight-bold">
                  {{ PAYMENT.paymentType[ticket?.Payment?.paymentType].text }}
                </p>
              </div>
              <div class="text-center">
                <p class="mb-0 lh-1">Total</p>
                <p class="mb-0 font-weight-bold">
                  {{ ticket?.Payment?.amount | currency }}
                </p>
              </div>
            </div>
          </v-card>

          <v-card outlined class="pa-3">
            <h6>Registro de validação</h6>
            <v-timeline
              v-if="ticket.TicketEntry.length > 0"
              align-top
              dense
              class="w-full"
            >
              <v-timeline-item
                v-for="(entry, index) in ticket.TicketEntry"
                :key="entry.id"
                large
                :color="entry.approved ? 'success' : 'warning'"
              >
                <template v-slot:icon>
                  <v-tooltip slot="icon" top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-if="entry?.approved === true"
                        size="22"
                        color="white"
                        v-on="on"
                      >
                        mdi-check
                      </v-icon>
                      <v-icon v-else size="22" color="white" v-on="on">
                        mdi-close
                      </v-icon>
                    </template>
                    <span>Entrada de Ticket</span>
                  </v-tooltip>
                </template>
                <div class="d-flex gap-3 align-center" style="flex: 3">
                  <div class="w-full">
                    <small
                      v-if="entry?.Period"
                      class="d-block text-overline lh-1"
                    >
                      Período: {{ entry?.Period.name || "Não informado" }}
                    </small>
                    <div
                      class="d-flex justify-space-between w-full align-center gap-3"
                    >
                      <span v-if="entry?.approved" class="font-weight-bold">
                        Validado em
                        {{ entry?.createdAt | date("DD/MM/YY HH:mm") }}
                      </span>
                      <span v-else class="font-weight-bold">
                        Recusado em
                        {{ entry?.createdAt | date("DD/MM/YY HH:mm") }}
                      </span>
                      <small class="d-block">#{{ entry?.id | shortId }}</small>
                    </div>
                    <small v-if="entry?.scannerName" class="d-block">
                      Em {{ entry?.scannerName }}
                    </small>
                    <small v-else class="d-block">
                      Por {{ entry?.RegisterBy }}
                    </small>
                  </div>
                </div>
                <v-divider class="mt-4" />
              </v-timeline-item>
            </v-timeline>
            <div v-else dense class="w-full text-center">
              <span type="info" class="mb-0" text>Não validado</span>
            </div>
          </v-card>
          <v-card
            v-if="ticket?.TicketTransfer.length > 0"
            outlined
            class="pa-3"
          >
            <h6>Transferência</h6>
            <v-timeline align-top dense class="w-full">
              <v-timeline-item large>
                <template v-slot:icon>
                  <v-tooltip slot="icon" top>
                    <template v-slot:activator="{ on }">
                      <v-icon size="22" color="white" v-on="on">
                        mdi-account-cash
                      </v-icon>
                    </template>
                    <span>Comprador original</span>
                  </v-tooltip>
                </template>
                <div class="d-flex gap-3 align-center" style="flex: 3">
                  <baseAvatar
                    :size="30"
                    :src="ticket.TicketTransfer[0].from.photo"
                    :seed="ticket.TicketTransfer[0].from.id"
                  />
                  <div>
                    <span class="font-weight-bold">
                      {{
                        ticket.TicketTransfer[0].from.name || "Conta não criada"
                      }}
                    </span>
                    <small class="d-block">
                      {{ ticket.TicketTransfer[0].from.phone }}
                    </small>
                  </div>
                </div>
                <v-divider class="mt-4" />
              </v-timeline-item>
              <v-timeline-item
                v-for="(transfer, i) in ticket.TicketTransfer"
                :key="transfer.id"
                class="pb-0"
                :color="
                  i + 1 == ticket.TicketTransfer.length ? 'success' : 'primary'
                "
              >
                <template v-slot:icon>
                  <span class="white--text font-weight-bold">{{ i + 1 }}</span>
                </template>
                <div class="d-flex gap-3" style="flex: 3">
                  <baseAvatar
                    class="mt-1"
                    :size="30"
                    :src="transfer.to.photo"
                    :seed="transfer.to.id"
                  />
                  <div class="flex-grow-1">
                    <span class="font-weight-bold">
                      {{ transfer.to.name || "Conta não criada" }}
                    </span>
                    <small class="d-block lh-1">
                      {{ transfer.to.phone }}
                    </small>
                  </div>
                  <small class="text-overline lh-1">
                    {{ transfer.createdAt | date("DD/MM/YY HH:mm") }}
                  </small>
                </div>
                <v-divider class="my-4" />
              </v-timeline-item>
            </v-timeline>
          </v-card>
          <v-card v-if="ticket?.RefoundRequest.length" outlined class="pa-3">
            <h6>Solicitação de reembolso</h6>
            <div>
              <div class="d-flex justify-space-between">
                <h6>Motivo</h6>
                <div>
                  <p class="mb-0 lh-1 font-weight-medium">
                    Solicitado em
                    {{
                      ticket?.RefoundRequest[0].createdAt
                        | date("DD/MM/YY HH:mm")
                    }}
                  </p>
                  <small class="lh-1">
                    {{
                      [
                        ticket.Payment.payedAt,
                        ticket?.RefoundRequest[0].createdAt,
                      ] | dateDiff
                    }}
                    após o pagamento
                  </small>
                </div>
              </div>
              <p class="mb-0">
                {{ ticket?.RefoundRequest[0].reason }}
              </p>
            </div>
            <div v-if="ticket?.RefoundRequest[0].answer" class="pt-3">
              <div class="d-flex justify-space-between">
                <h6>Resposta</h6>
                <div>
                  <p class="mb-0 lh-1 font-weight-medium">
                    Respondido em
                    {{
                      ticket?.RefoundRequest[0].closedAt
                        | date("DD/MM/YY HH:mm")
                    }}
                  </p>
                </div>
              </div>
              <p class="mb-0">
                {{ ticket?.RefoundRequest[0].answer }}
              </p>
            </div>
          </v-card>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close" small text block :disabled="loading">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import PARTY from "@/services/admin/party";
import PAYMENT from "@/utils/payment";

export default {
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data: () => ({
    dialog: false,
    loading: false,
    ticket: null,
    error: null,
    ticketId: null,
    PAYMENT,
  }),
  methods: {
    async getTicketDetails() {
      try {
        if (!this.dialog) return;
        this.loading = true;
        this.error = null;
        const response = await PARTY.reports.ticketDetails(
          this.party.organizationId,
          this.party.id,
          this.ticketId
        );
        this.ticket = response.ticket;
      } catch (e) {
        this.error = e.message || "Erro ao buscar detalhes da compra";
      } finally {
        this.loading = false;
      }
    },
    open(ticket) {
      if (this.dialog) return;
      this.dialog = true;
      this.ticket = null;
      this.ticketId = ticket.id;
      this.getTicketDetails();
    },
    close() {
      this.dialog = false;
      this.loading = false;

      // this.$emit("refresh");
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.$root.$on("ticket-details", this.open);
  },
};
</script>

<style></style>
