<template>
  <v-dialog
    v-model="dialog"
    width="600"
    scrollable
    :persistent="loading"
    @click:outside="close"
    eager
  >
    <v-card>
      <v-card-title>
        {{ seller?.id ? `Editar vendedor` : "Adicionar vendedor" }}
      </v-card-title>
      <v-card-text class="pt-1" v-if="seller">
        <v-card outlined class="pa-2 rounded-xl d-flex gap-2 mb-4">
          <baseAvatar
            v-if="seller.type !== 'pos' && seller.name"
            :src="seller.photo"
            :size="50"
            :seed="seller.id"
          />
          <v-avatar v-else size="50">
            <v-icon v-if="seller.type === 'pos'" x-large>
              mdi-cash-register
            </v-icon>
            <v-icon v-else x-large> mdi-account-question </v-icon>
          </v-avatar>

          <div class="d-flex flex-column justify-center">
            <b>
              {{ seller.name || seller.Address?.name || "Conta não criada" }}
            </b>
            <p class="mb-0">
              {{
                seller.phone ||
                (seller.Address &&
                  `${seller.Address.city} - ${seller.Address.state}`) ||
                ""
              }}
            </p>
          </div>
          <v-spacer />
          <div
            v-if="seller.permissions"
            class="d-flex flex-column align-end justify-center gap-1"
          >
            <v-chip
              label
              x-small
              v-if="seller.permissions.online"
              color="success"
            >
              <v-icon left x-small> mdi-web </v-icon>
              Venda Online
            </v-chip>
            <v-chip
              label
              x-small
              v-if="seller.permissions.offline"
              color="info"
            >
              <v-icon left x-small> mdi-account-cash </v-icon>
              Venda Em Dinheiro
            </v-chip>
          </div>
        </v-card>

        <v-alert :color="!!seller.SellerProfile ? 'success' : 'info'" dense>
          <div class="white--text d-flex justify-space-between">
            <span>
              {{ !!seller.SellerProfile ? "Exibir" : "Não exibir" }} perfil do
              vendedor na página do evento
            </span>
            <v-btn
              @click="editSellerProfile()"
              x-small
              color="white"
              class="mt-0 pt-0 black--text"
              elevation="0"
            >
              Gerenciar
            </v-btn>
          </div>
        </v-alert>

        <v-form v-model="valid" @submit.prevent ref="form">
          <h6 class="mb-0">Pode vender</h6>

          <v-tabs
            v-if="kitsGroups.length"
            v-model="tab"
            grow
            height="30px"
            class="mb-4"
          >
            <v-tab>
              <v-badge
                color="primary"
                :content="(seller?.TicketBlockSeller || []).length"
                inline
                :value="(seller?.TicketBlockSeller || []).length"
              >
                Lotes
              </v-badge>
            </v-tab>
            <v-tab>
              <v-badge
                color="primary"
                :content="(seller?.KitSeller || []).length"
                :value="(seller?.KitSeller || []).length"
                inline
              >
                Kits
              </v-badge>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <div>
                <v-card
                  v-for="p in groupsBySubParty"
                  :key="p.id || 'main'"
                  :outlined="groupsBySubParty.length > 1"
                  :class="{
                    'pa-3': groupsBySubParty.length > 1,
                    'px-1': groupsBySubParty.length == 1,
                  }"
                  class="mt-2"
                  elevation="0"
                >
                  <h6 v-if="groupsBySubParty.length > 1" class="text-14">
                    {{ p.name || "Principal" }}
                  </h6>
                  <v-card
                    outlined
                    class="pa-2 mt-2"
                    v-for="tg in p.ticketGroups"
                    :key="tg.id"
                  >
                    <div class="d-flex justify-space-between">
                      <h6 class="text-14 ml-1">{{ tg.name }}</h6>
                      <v-btn small text @click.stop="toggleAll(tg.TicketBlock)">
                        {{
                          tg.TicketBlock.some(
                            (tb) =>
                              !(seller.TicketBlockSeller || []).includes(tb.id)
                          )
                            ? "Marcar todos"
                            : "Desmarcar todos"
                        }}
                      </v-btn>
                    </div>
                    <v-row class="mx-0" no-gutters>
                      <v-col
                        cols="12"
                        sm="6"
                        v-for="tb in tg.TicketBlock"
                        :key="tb.id"
                      >
                        <v-checkbox
                          v-model="seller.TicketBlockSeller"
                          :value="tb.id"
                          :label="tb.name"
                          dense
                          multiple
                          hide-details
                          class="mt-0 pt-0"
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card>
              </div>
            </v-tab-item>
            <v-tab-item>
              <v-card
                v-for="p in groupsBySubParty"
                :key="p.id || 'main'"
                :outlined="groupsBySubParty.length > 1"
                :class="{
                  'pa-3': groupsBySubParty.length > 1,
                  'px-1': groupsBySubParty.length == 1,
                }"
                class="mt-2"
                elevation="0"
              >
                <h6 v-if="groupsBySubParty.length > 1" class="text-14">
                  {{ p.name || "Principal" }}
                </h6>

                <p
                  v-if="
                    groupsBySubParty.length > 1 &&
                    !p.ticketGroups.some((tg) => tg.KitList.length)
                  "
                  class="mb-0 text-center lh-3"
                >
                  Nenhum kit disponível nesse evento
                </p>

                <v-card
                  outlined
                  class="pa-3 mt-2"
                  v-for="tg in p.ticketGroups.filter((tg) => tg.KitList.length)"
                  :key="tg.id"
                >
                  <div class="d-flex justify-space-between">
                    <h6 class="text-14">{{ tg.name }}</h6>
                    <v-btn small text @click.stop="toggleAllKits(tg.KitList)">
                      {{
                        tg.KitList.some(
                          (kit) => !(seller.KitSeller || []).includes(kit.id)
                        )
                          ? "Marcar todos"
                          : "Desmarcar todos"
                      }}
                    </v-btn>
                  </div>
                  <v-row class="mx-0" no-gutters>
                    <v-col
                      cols="12"
                      sm="6"
                      v-for="kit in tg.KitList"
                      :key="kit.id"
                    >
                      <v-checkbox
                        v-model="seller.KitSeller"
                        :value="kit.id"
                        :label="kit.name"
                        dense
                        multiple
                        hide-details
                        class="mt-0 pt-0"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-form>

        <v-alert type="error" v-if="error" class="mt-2">
          {{ error }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-btn class="ml-1" text :disabled="loading" @click="close">
          Fechar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="ml-1"
          color="primary"
          :loading="loading"
          :disabled="!valid"
          @click="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import SELLER from "@/services/admin/party/seller";

export default {
  data: () => ({
    dialog: false,
    error: false,
    tab: 0,
    valid: false,
    loading: false,
    seller: null,
  }),

  methods: {
    editSellerProfile() {
      this.$root.$emit("seller-profile-modal", this.seller);
      this.close();
    },

    toggleAll(ticketBlocks) {
      const sellerTicketBlocks = this.seller.TicketBlockSeller || [];

      ticketBlocks = ticketBlocks.map((p) => p.id);
      const hasAll = ticketBlocks.every((p) => sellerTicketBlocks.includes(p));

      if (hasAll) {
        this.seller.TicketBlockSeller = sellerTicketBlocks.filter(
          (p) => !ticketBlocks.includes(p)
        );
      } else {
        this.seller.TicketBlockSeller = [
          ...new Set(sellerTicketBlocks.concat(ticketBlocks)),
        ];
      }
    },
    toggleAllKits(kits) {
      const sellerTicketBlocks = this.seller.KitSeller || [];

      kits = kits.map((p) => p.id);
      const hasAll = kits.every((p) => sellerTicketBlocks.includes(p));

      if (hasAll) {
        this.seller.KitSeller = sellerTicketBlocks.filter(
          (p) => !kits.includes(p)
        );
      } else {
        this.seller.KitSeller = [...new Set(sellerTicketBlocks.concat(kits))];
      }
    },
    async save() {
      try {
        this.loading = true;
        this.error = false;

        await SELLER.update(
          this.party.organizationId,
          this.party.id,
          this.seller.id,
          {
            ticketBlocks: this.seller.TicketBlockSeller,
            kits: this.seller.KitSeller,
            type: this.seller.type,
          }
        );

        this.$emit("success");
        this.loading = false;
        this.close();
      } catch (e) {
        this.loading = false;
        this.error = e.message || e;
      }
    },
    open(seller = {}) {
      this.seller = Object.assign({}, seller);

      if (seller.TicketBlockSeller)
        this.seller.TicketBlockSeller = seller.TicketBlockSeller.map(
          (tb) => tb.id
        );

      if (seller.KitSeller)
        this.seller.KitSeller = seller.KitSeller.map((kit) => kit.id);

      this.error = false;
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.$refs.form.reset();
      this.dialog = false;
      this.seller = null;
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    kitsGroups() {
      if (!this.ticketGroups) return [];
      return this.ticketGroups.filter((tg) => tg.KitList.length);
    },
    subParties() {
      return this.party?.ChildParties || [];
    },
    groupsBySubParty() {
      if (!this.ticketGroups) return [];
      if (!this.subParties.length) return [{ ticketGroups: this.ticketGroups }];
      const reducedSubParties = this.subParties.reduce(
        (acc, subParty) => {
          acc[subParty.id] = { ...subParty, ticketGroups: [] };
          return acc;
        },
        { [this.party.id]: { id: this.party.id, ticketGroups: [] } }
      );

      this.ticketGroups.forEach((tg) => {
        const subParty = tg.Party.id || "main";
        console.log(subParty, reducedSubParties[subParty]);
        reducedSubParties[subParty].ticketGroups.push(tg);
      });
      return Object.values(reducedSubParties).filter(
        (p) => p.ticketGroups.length
      );
    },
  },
  mounted() {
    this.$parent.$on("seller-modal", this.open);
  },
  watch: {},
  props: {
    party: {
      type: Object,
      required: true,
    },
    ticketGroups: {
      type: Array | Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
